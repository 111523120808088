import { makeVar } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

export const referralCode = makeVar("");
export const jobTotalCount = makeVar(0);
// type false is for error icon
export const gigSanckBar = makeVar({
  visible: false,
  backgroundColor: "#E5F8F3",
  borderColor: "#00BA88",
  color: "#00BA88",
  text: "upload",
  type: true,
  disableAutoHide: false
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getOkayGoJobLists: {
          keyArgs: false,
          merge(existing, incoming, other) {
            const mergedData = {
              content: [],
              totalElements: 0,
              totalPages: 0,
              nextPage: 0
            };
            if (other?.variables?.page_no === 0) {
              mergedData.content = [...incoming?.content];
            } else {
              mergedData.content = [
                ...(existing?.content || []),
                ...incoming?.content
              ];
              mergedData.nextPage = other?.variables?.page_no + 1;
            }
            mergedData.totalElements = incoming?.totalElements;
            mergedData.totalPages = incoming?.totalPages;
            return mergedData;
          }
        }
      }
    }
  }
});
